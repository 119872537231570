
import JigTooltip from '@/components/input/JigTooltip.vue'
import MoreActions from '@/components/material/MoreActions.vue'
import IconCopy from '@/components/svg/IconCopy.vue'
import IconInfo from '@/components/svg/IconInfoGray.vue'
import IconPencil from '@/components/svg/IconPencil.vue'
import IconShare from '@/components/svg/IconShare.vue'
import { JigConst } from '@/constants'
import { JigMetadata } from '@/store/modules/jig/types'
import { StandardObject } from '@/store/types'
import { eventBus } from '@/utils/eventBus'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * The JigActions component is part of the Jig Listing page data-table column.
 * It contains the actions buttons to manage a Jig, it includes following buttons and relevant actions:
 * - 1. Edit/View Jig Details button
 * - 2. Copy Jig button
 * - 3. Share Jig button
 * - 4. More actions button: Transfer, Open in web, Delete etc.
 *
 * Relevant actions will be emit via root event handler `eventBus`, and will be listened in appropriate components.
 * The reason to use eventBus is that Vue2 native $emit function can only cover self or direct parent.
 * eventBus is root level so it can be handled by any components.
 */
@Component({
  components: {
    'jig-tooltip': JigTooltip,
    'more-actions': MoreActions,
    'icon-copy': IconCopy,
    'icon-info': IconInfo,
    'icon-pencil': IconPencil,
    'icon-share': IconShare,
  },
})
export default class JigActions extends Vue {
  @Prop({ type: Boolean, default: false })
  public canCopyJig!: boolean
  @Prop({ type: Boolean, default: false })
  public canEditOrDeleteJig!: boolean
  @Prop({ type: Boolean, default: false })
  public canEnableTeamReshare!: boolean
  @Prop({ type: Boolean, default: true })
  public isActionEnabled!: boolean
  @Prop({ type: Boolean, default: false })
  public isCTAs!: boolean
  @Prop({ type: Boolean, default: true })
  public isShareDisabled!: boolean
  @Prop({ type: Boolean, default: false })
  public isTeamJigView!: boolean
  @Prop({ type: Object, default: () => ({}) })
  public jigItem!: JigMetadata

  private item: JigMetadata | StandardObject = {}

  @Watch('jigItem')
  private async onJigItemChange(value: JigMetadata) {
    this.item = value
  }

  protected mounted() {
    this.item = this.jigItem
  }

  private get jigEvents(): any {
    return JigConst.jigEvents
  }

  private get rootJigEvents(): any {
    return JigConst.rootJigEvents
  }

  private showJigPermissionsPrompt() {
    // This is root level event bus for sibling components as $emit and $on can only be used between parent <--> child
    eventBus.$emit(this.rootJigEvents.showJigPermissionPrompt, this.item)
  }

  private showCopyJigPrompt() {
    eventBus.$emit(this.rootJigEvents.showCopyJigPrompt, {
      jigId: this.item.Id,
      jigName: this.item.ProjectName,
      visibility: this.item.ProjectVisibility,
    })
  }

  private transferJigOwnership(payload: JigMetadata) {
    eventBus.$emit(this.rootJigEvents.onJigTransfer, payload)
  }

  private showDeleteJigPrompt() {
    eventBus.$emit(this.rootJigEvents.onJigDelete, {
      jigId: this.item.Id,
      jigName: this.item.ProjectName,
      jigFormatVersion: this.item.FormatVersion,
    })
  }
}
