
// Utilities
import AuthImage from '@/components/image/AuthImage.vue'
import IconChartLine from '@/components/svg/IconChartLine.vue'
import IconCube from '@/components/svg/IconCube.vue'
import IconDesktop from '@/components/svg/IconDesktop.vue'
import IconFourSquares from '@/components/svg/IconFourSquares.vue'
import IconHelp from '@/components/svg/IconHelp.vue'
import IconInfoOutlined from '@/components/svg/IconInfoOutlined.vue'
import IconStack from '@/components/svg/IconStack.vue'
import IconSubscription from '@/components/svg/IconSubscription.vue'
import IconSuitcase from '@/components/svg/IconSuitcase.vue'
import IconUserFrame from '@/components/svg/IconUserFrame.vue'
import IconUserGroup from '@/components/svg/IconUserGroup.vue'
import IconUsersFour from '@/components/svg/IconUsersFour.vue'
import { Permissions } from '@/security/permissions'
import { PermissionActions } from '@/store/constants'
import { GetTenantsByUserRequest, Tenant, TenantToken } from '@/store/modules/app/types'
import { DynaconfConfig, Namespace } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

declare var consoleLog: any

const tenantPerm = 'tenant'
const dashboardPerm = 'dashboard'
const staffPerm = 'staff'
const superUserPerm = 'superUser'
const libraryPerm = 'library'

@Component({
  components: {
    AuthImage,
    IconChartLine,
    IconCube,
    IconDesktop,
    IconFourSquares,
    IconHelp,
    IconInfoOutlined,
    IconStack,
    IconSubscription,
    IconSuitcase,
    IconUserFrame,
    IconUserGroup,
    IconUsersFour,
  },
})
export default class DrawerVue extends Vue {
  @State('dynaconfConfig', { namespace: Namespace.App })
  public dynaconfConfig!: DynaconfConfig
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('tenantUserCount', { namespace: Namespace.App })
  public tenantUserCount!: number
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('allTenantsCurrentPage', { namespace: Namespace.Utils })
  public allTenantsCurrentPage!: number

  @Mutation('changeInitializingState', { namespace: Namespace.Utils })
  public changeInitializingState: any
  @Mutation('setDrawer', { namespace: Namespace.Utils })
  public setDrawer: any
  @Mutation('toggleDrawer', { namespace: Namespace.Utils })
  public toggleDrawer: any
  @Mutation('updateSuperUserFlag', { namespace: Namespace.App })
  public updateSuperUserFlag: any

  @Action('getDynaconfConfig', { namespace: Namespace.App })
  public getDynaconfConfig: any
  @Action('forceRerenderCoreView', { namespace: Namespace.App })
  public forceRerenderCoreView: any
  @Action('loadMyTenants', { namespace: Namespace.App })
  public loadMyTenants: any
  @Action('loadTenantUsers', { namespace: Namespace.App })
  public loadTenantUsers: any
  @Action('loadTenantsVisibleToSuperUsers', { namespace: Namespace.App })
  public loadTenantsVisibleToSuperUsers: any
  @Action('updateMyTenantsWithNewTenant', { namespace: Namespace.App })
  public updateMyTenantsWithNewTenant: any
  @Action('setMyTenantIndex', { namespace: Namespace.App })
  public setMyTenantIndex: any
  @Action('setMyPreviousTenantIndex', { namespace: Namespace.App })
  public setMyPreviousTenantIndex: any
  @Action('setMyTenantLogoByID', { namespace: Namespace.App })
  public setMyTenantLogoByID: any

  private logoImage: string = ''
  private logoImageUrl: string = ''
  private showingDefaultLogo: boolean = true
  private tenantName: string = 'JigSpace'
  private hasTenant: boolean = false
  private hasJigStaffTenant: boolean = false
  private links: any = {
    workshop: {
      name: '',
      items: [
        {
          to: '/my-jigs',
          icon: 'icon-four-squares',
          text: 'My Jigs',
          requires: [tenantPerm, dashboardPerm],
        },
        {
          to: '/team-jigs',
          icon: 'icon-user-frame',
          text: 'Team Jigs',
          requires: [tenantPerm, dashboardPerm],
        },
        {
          to: '/library',
          icon: 'icon-cube',
          text: 'Team objects',
          requires: [tenantPerm, dashboardPerm, libraryPerm],
        },
      ],
    },
    team: {
      name: 'Team settings',
      items: [
        {
          to: '/tenant-users',
          icon: 'icon-user-group',
          text: 'Team seats',
          requires: [tenantPerm, dashboardPerm],
        },
        {
          to: '/tenant',
          icon: 'icon-suitcase',
          text: 'Custom branding',
          requires: [tenantPerm, dashboardPerm],
        },
        {
          to: '/subscription',
          icon: 'icon-subscription',
          text: 'Subscription',
          requires: [tenantPerm, dashboardPerm],
          condition: {
            isNewPricingSet: true,
          },
        },
      ],
    },
    other: {
      name: '',
      items: [
        {
          to: '/download',
          icon: 'icon-desktop',
          text: 'Download app',
          requires: [],
          target: '_blank',
        },
        {
          to: '',
          href: 'https://www.jig.com/help-center/',
          icon: 'icon-help',
          text: 'Help Center',
          requires: [],
          target: '_blank',
        },
        {
          to: '/tenants',
          icon: 'icon-users-four',
          text: 'All teams',
          requires: [tenantPerm, dashboardPerm, staffPerm, superUserPerm],
        },
        {
          to: '/versions',
          icon: 'icon-info-outlined',
          text: 'App versions',
          requires: [tenantPerm, dashboardPerm, staffPerm],
        },
        {
          to: '/library-categories',
          icon: 'icon-stack',
          text: 'Library categories',
          requires: [tenantPerm, dashboardPerm, staffPerm],
        },
        {
          to: '/command',
          icon: 'icon-chart-line',
          text: 'Command centre',
          requires: [dashboardPerm, staffPerm],
        },
        // It can be uncommented to quickly test a pure setup js+vue+vuetify2.2 page
        // {
        //   to: '/dashboard',
        //   icon: 'mdi-account',
        //   text: 'Dashboard',
        // },
      ],
    },
  }
  private responsive: boolean = false

  @Watch('myTenant')
  private onMyTenantChanged(tenant: Tenant) {
    this.setTenantNameAndLogo(tenant)
  }

  @Watch('allTenantsCurrentPage')
  private onAllTenantsCurrentPage(allTenantsCurrentPage: number) {
    this.links.other.items.forEach((link: any) => {
      if (link.to.startsWith('/tenants')) {
        link.to = `/tenants?page=${allTenantsCurrentPage.toString()}`
      }
    })
  }

  private get activeLinks() {
    const drawer = this
    // If the user is a Super User, they can access all tenants/dashboard even though they don't
    // have tenant tokens for each tenant. Hence we start with this as our default state.
    let tenantCanAccessDashboard = drawer.isSuperUser
    const myTenantIndex = drawer.$store.state.app.myTenantIndex
    let hasLibraryPerm = true
    if (!tenantCanAccessDashboard && myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const tenant = TenantHelpers.GetTenantByIndex(myTenantIndex, drawer.$store.state.app.myTenants)
      const tenantTokens = drawer.$auth0.tenants

      if (tenant !== null && tenant !== undefined) {
        const tenantToken = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenant.ID)
        if (tenantToken !== undefined) {
          tenantCanAccessDashboard = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermDashboard, PermissionActions.Login)

          hasLibraryPerm = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermLibrary, PermissionActions.View)
        }
      }
    }
    const filteredLinks: any = {}
    for (const key in drawer.links) {
      if (drawer.links[key]) {
        const linksArray = drawer.links[key]
        filteredLinks[key] = {
          name: linksArray.name,
          items: [],
        }

        for (const link of linksArray.items) {
          if ((link.requires === undefined || link.requires.length === 0) && this.isConditionEmpty(link.condition)) {
            filteredLinks[key].items.push(link)
          } else {
            let allowed = false
            for (const req of link.requires) {
              switch (req) {
                case tenantPerm:
                  allowed = drawer.hasTenant
                  break
                case dashboardPerm:
                  allowed = tenantCanAccessDashboard
                  break
                case staffPerm:
                  allowed = drawer.hasJigStaffTenant
                  break
                case superUserPerm:
                  allowed = drawer.isSuperUser
                  break
                case libraryPerm:
                  allowed = hasLibraryPerm
                  break
                default:
                  allowed = false
                  break
              }

              if (!allowed) {
                break
              }
            }

            if (!this.isConditionEmpty(link.condition)) {
              allowed = allowed && this.hasMetCondition(link.condition)
            }

            if (allowed) {
              // If we're here and allowed then all reqs were true
              filteredLinks[key].items.push(link)
            }
          }
        }
      }
    }

    return filteredLinks
  }

  private get items(): any {
    return this.$t('Layout.View.items')
  }

  private get inputValue(): any {
    return this.$store.state.utils.drawer
  }

  private set inputValue(val: any) {
    this.setDrawer(val)
  }

  protected mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    // Re-run login logic because this component may not have mounted before login event was fired from $auth
    this.handleLoginEvent()
    this.setTenantNameAndLogo(this.myTenant)
  }

  protected beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  }

  private onResponsiveInverted() {
    if (window.innerWidth < 991) {
      this.responsive = true
    } else {
      this.responsive = false
    }
  }

  private isStaffOnly(permissions: string[]) {
    return permissions.includes(staffPerm)
  }

  private setTenantNameAndLogo(tenant: Tenant) {
    if (tenant.ID === TenantHelpers.InvalidTenantID) {
      // Hasn't actually loaded yet so set default.
      this.logoImage = tenant.LogoImage
      this.showingDefaultLogo = true
      return
    }

    this.tenantName = tenant.Name
    // Its pretty confusing but if there is ANY .Logourl set (even if it looks like the default) then we should try to fetch the custom one.
    TenantHelpers.SetTenantDefaultLogoIfNull(tenant)
    if (tenant.Logourl.length > 0 && (TenantHelpers.HasDefaultLogoImage(tenant) || tenant.LogoUpdated)) {
      this.showingDefaultLogo = false
      // Interestingly our logo urls when customized are all at the same path,
      // it's just the tenant id in the x-tenant-id header changes and delivers a different png.
      // To update the auth-image, we need to change its src though and setting it to '' then 100ms later setting it back is a quick way to do that
      // and the ux effect isn't so bad.
      this.logoImageUrl = ''
      setTimeout(() => {
        this.logoImageUrl = `${process.env.VUE_APP_API_DOMAIN}/tenants/v1/images/v1`
      }, 100)
    } else {
      this.showingDefaultLogo = true
      this.logoImage = tenant.LogoImage
    }
  }

  private async onMyTenantsRetrieved() {
    let canUseDashboard = false
    const tenantTokens = this.$auth0.tenants

    const tenants = this.$store.state.app.myTenants
    if (tenants !== undefined && tenants !== null) {
      for (const t of tenantTokens) {
        const token = t as TenantToken
        const tenant = TenantHelpers.GetTenantByID(token.id, tenants)

        if (tenant !== undefined && tenant !== null) {
          token.name = tenant.Name
        }

        // Let any tenant user who has access to the dashboard, use the dashboard.
        if (!canUseDashboard) {
          canUseDashboard = Permissions.TokenHasPermissionAction(token, Permissions.PermDashboard, PermissionActions.Login)
        }
      }
    }

    // We need to get the tenant names filled in the tokens before we can determine if the user is a staff member.
    TenantHelpers.TrySetJigSpaceStaffTenantID(tenantTokens)
    this.hasJigStaffTenant = TenantHelpers.HasJigSpaceStaffToken(tenantTokens)

    if (!canUseDashboard) {
      this.hasTenant = false
      this.hasJigStaffTenant = false
      // TODO: pull out all the logic to determine if dashboard-restricted and put it into callback where we handle most post-login logic that is conflicting with this.
      consoleLog(`Directing to /dashboard-restricted from drawer beacause canUseDashboard = ${canUseDashboard}`)
      await this.$router.push('/dashboard-restricted')
    }
  }

  private async handleLoginEvent() {
    if (window.location.pathname === '/email-verified-buy' || window.location.pathname === '/email-verified') {
      consoleLog(`Drawer handleLoginEvent but window.location.pathname is ${window.location.pathname} so returning early`)
      return
    }
    this.changeInitializingState(true)
    const tenantTokens = this.$auth0.tenants

    this.updateSuperUserFlag(TenantHelpers.IsSuperUser(tenantTokens))
    this.hasJigStaffTenant = false
    this.hasTenant = !(tenantTokens == null || tenantTokens.length === 0)
    // If we're logged in, we need to load my tenants for the toolbar to use. We don't
    // want to allow the drawer to show any navigation (that could interrupt our request) while
    // this happens.
    if (this.$auth0.isAuthenticated) {
      if (sessionStorage.getItem('previousUid') === this.$auth0.uid.toString()) {
        const strIndex = sessionStorage.getItem('myTenantIndex')
        if (strIndex !== '') {
          const previousTenantIndex = Number(strIndex)
          this.setMyPreviousTenantIndex(previousTenantIndex)
        }
      } else {
        sessionStorage.setItem('myTenantIndex', TenantHelpers.InvalidTenantIndex.toString())
      }
      sessionStorage.setItem('previousUid', this.$auth0.uid)
      const reqPayload = {
        UrlParams: {
          all: 'false',
        },
        ModalLoad: false,
      } as GetTenantsByUserRequest
      await this.loadMyTenants(reqPayload)

      if (Object.keys(this.dynaconfConfig.pricing.sets).length === 0) {
        await this.getDynaconfConfig()
      }

      if (this.tenantUserCount === TenantHelpers.InvalidTenantUserCount) {
        await this.loadTenantUsers()
      }

      await this.onMyTenantsRetrieved()
      this.changeInitializingState(false)
    }
  }

  private hasMetCondition(condition: any) {
    let validCondition = true

    Object.keys(condition).forEach((key) => {
      switch (key) {
        case 'isNewPricingSet':
          if (this.myTenant.Subscription) {
            validCondition =
              validCondition && this.dynaconfConfig.pricing.sets.hasOwnProperty(this.myTenant.Subscription.PricingSet) === condition[key]
          }
          break
        default:
          break
      }
    })

    return validCondition
  }

  private isConditionEmpty(condition: any) {
    return condition === undefined || Object.keys(condition).length === 0
  }
}
