
import UserAvatar from '@/components/image/UserAvatar.vue'
import TenantSwitcher from '@/components/modals/TenantSwitcher.vue'
import IconSignIn from '@/components/svg/IconSignIn.vue'
import IconSignOut from '@/components/svg/IconSignOut.vue'
import IconSwitch from '@/components/svg/IconSwitch.vue'
import IconUser from '@/components/svg/IconUser.vue'
import { TenantConst } from '@/constants'
import { Tenant, TenantUser, UserProfile } from '@/store/modules/app/types'
import { Namespace, StandardObject } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { Mutation, State } from 'vuex-class'

declare var consoleLog: any

@Component({
  components: {
    'tenant-switcher': TenantSwitcher,
    'user-avatar': UserAvatar,
    'icon-user': IconUser,
    'icon-switch': IconSwitch,
    'icon-signout': IconSignOut,
    'icon-signin': IconSignIn,
  },
  watch: {
    $route(val: any) {
      this.title = val.name
    },
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('utils', ['toggleDrawer']),
  },
})
export default class AppBarVue extends Vue {
  @State('drawer', { namespace: Namespace.Utils })
  public drawer!: boolean
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('currentSignedInUser', { namespace: Namespace.App })
  public currentSignedInUser!: TenantUser | null

  @Mutation('setDrawer', { namespace: Namespace.Utils })
  public setDrawer: any

  public $refs!: Vue['$refs'] & {
    tenantSwitcherDialog: TenantSwitcher
  }

  private isAuthenticated: boolean = false
  private isSmallViewport: boolean = false
  private profile: any = null

  protected mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    // Re-run login logic because this component may not have mounted before login event was fired from $auth
    this.handleLoginEvent({
      loggedIn: this.$auth0.isAuthenticated,
      profile: this.$auth0.user,
    })
  }

  private get signedInUser(): TenantUser | UserProfile | undefined {
    if (this.profile == null) {
      return undefined
    }

    return this.tenantUsers.find((user: TenantUser) => user.Uid === this.profile[TenantConst.auth0ProfileKeys.uid]) || this.profile
  }

  private get userDisplayName(): string {
    if (this.signedInUser == null) {
      return 'Account'
    }

    return this.signedInUser.name || this.signedInUser.email
  }

  private get getMenuTriggerButton(): StandardObject {
    if (!this.signedInUser) {
      // return general user icon
      return {
        component: 'icon-user',
        props: {
          class: 'icon icon-user button__icon button__icon--left',
        },
      }
    }

    // return user avatar
    return {
      component: 'user-avatar',
      props: {
        userEmail: this.signedInUser.email,
        userName: this.signedInUser.name || this.signedInUser.username,
      },
    }
  }

  private async handleLoginEvent(data: any) {
    this.isAuthenticated = data.loggedIn
    this.profile = data.profile
  }

  private onClickBtn() {
    this.setDrawer(!this.$store.state.utils.drawer)
  }

  private showTenantSwitcherDialog() {
    this.$refs.tenantSwitcherDialog.ShowDialog()
  }

  private logout() {
    // Emit on-logout event to fire any function to run before logout. i.e. tracking.
    this.$emit('on-logout', () => {
      // We need to wait till any other event to be done before we logout.
      // At this stage it will be the identify tracking event needs to be run before logout.
      consoleLog('Start logout process')
      Vue.prototype.$auth0.logout()
      this.$router.push({ path: '/' })
    })
  }

  protected onResponsiveInverted() {
    if (window.innerWidth < 991) {
      this.isSmallViewport = true
    } else {
      this.isSmallViewport = false
    }
  }
}
