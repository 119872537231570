
import { UtilsConst } from '@/constants'
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import Axios, { AxiosRequestConfig } from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

declare var consoleLog: any

@Component({
  computed: {},
})
export default class AuthImageVue extends Vue {
  @Prop({ default: '' })
  public src!: string
  @Prop({ default: false })
  public requiresAuth!: boolean
  @Prop({ default: false })
  public responseAlreadyBase64!: boolean
  @Prop({ default: 1 })
  public aspectRatio!: 1
  @Prop({ default: 30 })
  public minWidth!: 30
  @Prop({ default: 'none' })
  public maxWidth!: 'none'
  @Prop({ default: false })
  public requireCustomLazySrc!: boolean
  // Prop to use native <img> instead. Using native <img> tag will make browsers add the option "Copy Image" and "Save Image As"" in the right-click context menu
  @Prop({ default: false })
  public useNativeImgTag!: boolean

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  private secureSrc: string = ''
  private blobUrl: string = ''
  private isLoadingAsset: boolean = false

  @Watch('src')
  onSrcChanged(val: string, oldVal: string) {
    consoleLog(`auth-image src changed to ${val}`)
    this.revokeBlobIfExists()
    this.getSecureSrc()
  }

  private get placeholder(): string {
    return `${window.location.origin}/img/Thumbnail_PlaceholderPro_512.png`
  }

  private get isCustomLazySrcRequired(): boolean {
    return this.requireCustomLazySrc && this.isLoadingAsset
  }

  protected async created() {
    this.getSecureSrc()
  }

  protected async destroyed() {
    this.revokeBlobIfExists()
  }

  protected async getSecureSrc(downloadURL: string = '') {
    if (!downloadURL && !this.src) return

    if (!downloadURL) {
      this.isLoadingAsset = true
      this.secureSrc = ''
    }

    if (this.requiresAuth) {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${Vue.prototype.$auth0.accessToken}`,
        },
      }
      if (this.myTenant != null && config.headers != null) {
        // Although the special tenant id header is only required when they have >1 tenant, we may as well always send it.
        config.headers[UtilsConst.RequestHeaders.CustomHeaderKeyTenantId] = this.myTenant.ID
      }
      if (!this.responseAlreadyBase64) {
        config.responseType = 'blob'
      }
      try {
        const response = await Axios.get(downloadURL || this.src, config)

        if (response.status === 200) {
          if (this.responseAlreadyBase64) {
            const secureBase64 = 'data:image/png;base64,'.concat(response.data)
            if (downloadURL) {
              return secureBase64
            }

            this.secureSrc = secureBase64
          } else {
            const secureBase64Url = URL.createObjectURL(new Blob([response.data], { type: 'image/png' }))

            if (downloadURL) {
              consoleLog(`Requested image for downloading ${downloadURL} and resulted in blob at returning ${secureBase64Url}`)
              return secureBase64Url
            }

            this.secureSrc = secureBase64Url
            this.blobUrl = this.secureSrc
            consoleLog(`Loaded image for ${this.src} and resulted in blob at ${this.blobUrl}`)
          }
        } else {
          consoleLog(`Tried to get a secure image from ${this.src} but response was ${JSON.stringify(response)}`)
        }
      } catch (error: any) {
        this.onError(`${error.message}.\nFull Error: ${JSON.stringify(error)}`)
      }
    } else {
      this.secureSrc = this.src
      consoleLog(`Displayed asset ${this.secureSrc} directly.`)
    }

    this.isLoadingAsset = false
  }

  protected revokeBlobIfExists() {
    if (this.blobUrl) {
      consoleLog(`Revoking blob at ${this.blobUrl}`)
      // The browser will use memory to keep the link to the file available. So we must clear it on destroyed.
      URL.revokeObjectURL(this.blobUrl)
    }
  }

  private onError(err: any) {
    consoleLog(`Tried to load secure image but got error: ${err}`)
    this.secureSrc = this.placeholder
  }

  public async GetSecureDownloadLink(downloadLink: string = '') {
    return this.getSecureSrc(downloadLink)
  }
}
