
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})
export default class ViewVue extends Vue {
  @State('forceRerenderKey', { namespace: Namespace.App })
  public forceRerenderKey!: number
}
