
import { AppHelper } from '@/utils/app-helper'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class UserAvatarVue extends Vue {
  @Prop({ type: String, default: 'N/A' })
  public userEmail!: string
  @Prop({ type: String, default: '/' })
  public userName!: string

  private get userInitial() {
    return this.userName ? this.userName.substring(0, 1) : ''
  }

  private get userAvatarColor() {
    return this.isEmailEmpty ? '' : AppHelper.userAvatarColor(this.userEmail)
  }

  private get isEmailEmpty(): boolean {
    return this.userEmail === '' || this.userEmail === 'N/A'
  }
}
