
import { AppConst, UtilsConst } from '@/constants'
import { Permissions } from '@/security/permissions'
import { PermissionActions } from '@/store/constants'
import { Tenant, TenantToken } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import Axios, { AxiosRequestConfig } from 'axios'
import initHelpHero, { HelpHero } from 'helphero'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

declare var consoleLog: any

@Component({ computed: {} })
export default class ExternalAnalyticsVue extends Vue {
  private readonly hubSpotScriptId = 'hub-spot'

  @State('myTenant', { namespace: Namespace.App }) // The @State decoration is required for the @Watch to work
  public myTenant!: Tenant

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    // There are some things we want to find out about the logged in user AND their selected tenant, to then properly identify with our tutorial system: HelpHero
    let tenantToken: TenantToken | undefined = undefined
    let isBillingAdmin: boolean = false
    let isOrganisationManager: boolean = false
    if (Vue.prototype.$auth0.tenants && Vue.prototype.$auth0.tenants.length > 0) {
      tenantToken = TenantHelpers.GetTenantTokenByTenantID(Vue.prototype.$auth0.tenants, value.ID)
      if (tenantToken) {
        isBillingAdmin = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermTenant, PermissionActions.BillingContact)
        isOrganisationManager = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermTenant, PermissionActions.Manage)
      }
      consoleLog(`myTenant changed to ${value.Name}. isOrganisationManager: ${isOrganisationManager} isBillingAdmin: ${isBillingAdmin}`)
      ;(Vue.prototype.$hlp as HelpHero).identify(Vue.prototype.$auth0.user.email, {
        email: Vue.prototype.$auth0.user.email,
        isBillingAdmin,
        isOrganisationManager,
        tenantName: value.Name,
        tenantLogoUrl: value.Logourl,
        Subscription: AppHelper.getTierType(value.Subscription.TierType),
      })
    } else {
      consoleLog(`myTenant changed but tenants array is empty`)
    }
  }

  protected async created() {
    this.initHelpHero()
    // Chaining the async script loads to resolve a suspected race condition which can hang the browser at 100% cpu
    this.initHubSpot(() => {})
  }

  private initHubSpot(onFinish: any) {
    this.loadAsyncScript(
      this.hubSpotScriptId,
      `https://js.hs-scripts.com/${AppConst.hubspotId}.js`,
      async () => {
        // https://developers.hubspot.com/docs/api/conversation/visitor-identification
        // We only bother getting a hs id token if the actual user id authenticated with a token from us first
        if (Vue.prototype.$auth0.isAuthenticated) {
          ;(window as any).hsConversationsSettings = {
            loadImmediately: false,
          } as any
          ;(window as any).hsConversationsOnReady = [this.onConversationsAPIReady]
          var token = ''
          var email = Vue.prototype.$auth0.user.email
          try {
            // Our api makes a protected POST call to hubspot visitor id api, then returns the response with their token.
            // Returning an error from our own api shouldn't prevent the widget from loading, it will just load as 'Unknown visitor'.
            const config: AxiosRequestConfig = {
              headers: {
                Authorization: `Bearer ${Vue.prototype.$auth0.accessToken}`,
                [UtilsConst.RequestHeaders.CustomHeaderKeyTenantId]: this.myTenant.ID,
              },
            }
            var response = await Axios.get(`${AppConst.apiDomain}${AppConst.APIEndpoint.hubSpotVisitorIdTokenUrl}`, config)
            if (response.status == 200) {
              consoleLog('Success getting HubSpot Visitor Id Token')
              token = response.data.token
            } else {
              consoleLog(`Tried to get a hubspot visitor id token but response was ${JSON.stringify(response)}`)
            }
          } catch (error) {
            consoleLog(`Tried to get a hubspot visitor id token but error ${JSON.stringify(error)}`)
          } finally {
            ;(window as any).hsConversationsSettings = {
              identificationEmail: email,
              identificationToken: token,
            }
          }
        }
      },
      undefined,
      onFinish
    )
  }

  private onConversationsAPIReady() {
    // The HS chat will load on dev and prod dashboard. To test on localhost or anywhere else: use the query parameter `hschat=1`.
    consoleLog('Loading HS Chat widget')
    ;(window as any).HubSpotConversations.widget.load()
  }

  private initHelpHero() {
    consoleLog('help-hero script loading.')
    Vue.prototype.$hlp = initHelpHero('BoOkWcAZeTd')
    this.registerHelpHeroEventToGA('tour_started')
    this.registerHelpHeroEventToGA('tour_completed')
    this.registerHelpHeroEventToGA('tour_advanced')
    this.registerHelpHeroEventToGA('tour_cancelled')
    this.registerHelpHeroEventToGA('tour_interrupted')
    this.registerHelpHeroEventToGA('error')
    consoleLog('help-hero initialized.') // Called help-hero here to be consistent with the other console logs which use the script id.
  }

  private registerHelpHeroEventToGA(eventName: string) {
    ;(Vue.prototype.$hlp as HelpHero).on(eventName as any, (ev: any, info: any) => {
      this.$gtm.trackEvent({ category: 'Tour', action: ev.kind, label: info.tour ? info.tour.name : 'undefined' })

      segmentEventTracking('Tour', {
        action: ev.kind,
        label: info.tour ? info.tour.name : 'undefined',
      })
    })
  }

  // onload() will happen only on successful script load, while onFinish will happen on successful and failed script load, so it can be used to chain script loading.
  private async loadAsyncScript(scriptId: string, scriptSrc: string, beforeload: any, onload: any, onFinish: any) {
    if (!scriptId || !scriptSrc) {
      consoleLog('loadAsyncScript needs a scriptId and scriptSrc.')
      return
    }
    if (document.getElementById(scriptId) !== null) {
      consoleLog(`loadAsyncScript but ${scriptId} already loaded.`)
      return
    }
    if (beforeload) {
      await beforeload()
    }
    let script = document.createElement('script')
    script.setAttribute('src', scriptSrc)
    script.defer = true
    script.async = true
    script.type = 'text/javascript'
    script.id = scriptId
    if (onload) {
      script.onload = () => {
        onload()
        if (onFinish) {
          onFinish()
        }
      }
    } else {
      script.onload = () => {
        consoleLog(`${scriptId} initialized.`)
        if (onFinish) {
          onFinish()
        }
      }
    }
    script.onerror = () => {
      consoleLog(`${scriptId} script encountered error on load. `)
      if (onFinish) {
        onFinish()
      }
    }
    consoleLog(scriptId + ' script loading.')
    document.head.appendChild(script)
  }
}
